export const RkAccordion = () => import('../../rockitUI/components/components/organisms/RkAccordion/RkAccordion.vue' /* webpackChunkName: "components/rk-accordion" */).then(c => wrapFunctional(c.default || c))
export const RkCarousel = () => import('../../rockitUI/components/components/organisms/RkCarousel/RkCarousel.vue' /* webpackChunkName: "components/rk-carousel" */).then(c => wrapFunctional(c.default || c))
export const RkFiltersSidebar = () => import('../../rockitUI/components/components/organisms/RkFiltersSidebar/RkFiltersSidebar.vue' /* webpackChunkName: "components/rk-filters-sidebar" */).then(c => wrapFunctional(c.default || c))
export const RkFooterLinks = () => import('../../rockitUI/components/components/organisms/RkFooterLinks/RkFooterLinks.vue' /* webpackChunkName: "components/rk-footer-links" */).then(c => wrapFunctional(c.default || c))
export const RkCollectedProduct = () => import('../../rockitUI/components/components/organisms/RkCollectedProduct/RkCollectedProduct.vue' /* webpackChunkName: "components/rk-collected-product" */).then(c => wrapFunctional(c.default || c))
export const RkHeader = () => import('../../rockitUI/components/components/organisms/RkHeader/RkHeader.vue' /* webpackChunkName: "components/rk-header" */).then(c => wrapFunctional(c.default || c))
export const RkList = () => import('../../rockitUI/components/components/organisms/RkList/RkList.vue' /* webpackChunkName: "components/rk-list" */).then(c => wrapFunctional(c.default || c))
export const RkMobileMenu = () => import('../../rockitUI/components/components/organisms/RkMobileMenu/RkMobileMenu.vue' /* webpackChunkName: "components/rk-mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const RkProductCard = () => import('../../rockitUI/components/components/organisms/RkProductCard/RkProductCard.vue' /* webpackChunkName: "components/rk-product-card" */).then(c => wrapFunctional(c.default || c))
export const RkProductCardHorizontal = () => import('../../rockitUI/components/components/organisms/RkProductCardHorizontal/RkProductCardHorizontal.vue' /* webpackChunkName: "components/rk-product-card-horizontal" */).then(c => wrapFunctional(c.default || c))
export const RkProductCardHorizontalCart = () => import('../../rockitUI/components/components/organisms/RkProductCardHorizontalCart/RkProductCardHorizontalCart.vue' /* webpackChunkName: "components/rk-product-card-horizontal-cart" */).then(c => wrapFunctional(c.default || c))
export const RkPromoBar = () => import('../../rockitUI/components/components/organisms/RkPromoBar/RkPromoBar.vue' /* webpackChunkName: "components/rk-promo-bar" */).then(c => wrapFunctional(c.default || c))
export const RkSidebar = () => import('../../rockitUI/components/components/organisms/RkSidebar/RkSidebar.vue' /* webpackChunkName: "components/rk-sidebar" */).then(c => wrapFunctional(c.default || c))
export const RkTable = () => import('../../rockitUI/components/components/organisms/RkTable/RkTable.vue' /* webpackChunkName: "components/rk-table" */).then(c => wrapFunctional(c.default || c))
export const RkTabs = () => import('../../rockitUI/components/components/organisms/RkTabs/RkTabs.vue' /* webpackChunkName: "components/rk-tabs" */).then(c => wrapFunctional(c.default || c))
export const RkAccordionItem = () => import('../../rockitUI/components/components/organisms/RkAccordion/_internal/RkAccordionItem.vue' /* webpackChunkName: "components/rk-accordion-item" */).then(c => wrapFunctional(c.default || c))
export const RkCarouselItem = () => import('../../rockitUI/components/components/organisms/RkCarousel/_internal/RkCarouselItem.vue' /* webpackChunkName: "components/rk-carousel-item" */).then(c => wrapFunctional(c.default || c))
export const RkFooterLinksColumn = () => import('../../rockitUI/components/components/organisms/RkFooterLinks/_internal/RkFooterLinksColumn.vue' /* webpackChunkName: "components/rk-footer-links-column" */).then(c => wrapFunctional(c.default || c))
export const RkListItem = () => import('../../rockitUI/components/components/organisms/RkList/_internal/RkListItem.vue' /* webpackChunkName: "components/rk-list-item" */).then(c => wrapFunctional(c.default || c))
export const RkProductCardLoading = () => import('../../rockitUI/components/components/organisms/RkProductCard/_internal/RkProductCardLoading.vue' /* webpackChunkName: "components/rk-product-card-loading" */).then(c => wrapFunctional(c.default || c))
export const RkProductCardHorizontalLoading = () => import('../../rockitUI/components/components/organisms/RkProductCardHorizontal/_internal/RkProductCardHorizontalLoading.vue' /* webpackChunkName: "components/rk-product-card-horizontal-loading" */).then(c => wrapFunctional(c.default || c))
export const RkTableData = () => import('../../rockitUI/components/components/organisms/RkTable/_internal/RkTableData.vue' /* webpackChunkName: "components/rk-table-data" */).then(c => wrapFunctional(c.default || c))
export const RkTableHeader = () => import('../../rockitUI/components/components/organisms/RkTable/_internal/RkTableHeader.vue' /* webpackChunkName: "components/rk-table-header" */).then(c => wrapFunctional(c.default || c))
export const RkTableHeading = () => import('../../rockitUI/components/components/organisms/RkTable/_internal/RkTableHeading.vue' /* webpackChunkName: "components/rk-table-heading" */).then(c => wrapFunctional(c.default || c))
export const RkTableRow = () => import('../../rockitUI/components/components/organisms/RkTable/_internal/RkTableRow.vue' /* webpackChunkName: "components/rk-table-row" */).then(c => wrapFunctional(c.default || c))
export const RkTab = () => import('../../rockitUI/components/components/organisms/RkTabs/_internal/RkTab.vue' /* webpackChunkName: "components/rk-tab" */).then(c => wrapFunctional(c.default || c))
export const KlarnaOSM = () => import('../../rockitUI/components/components/molecules/KlarnaOSM/KlarnaOSM.vue' /* webpackChunkName: "components/klarna-o-s-m" */).then(c => wrapFunctional(c.default || c))
export const RkAddToCart = () => import('../../rockitUI/components/components/molecules/RkAddToCart/RkAddToCart.vue' /* webpackChunkName: "components/rk-add-to-cart" */).then(c => wrapFunctional(c.default || c))
export const RkAddressPicker = () => import('../../rockitUI/components/components/molecules/RkAddressPicker/RkAddressPicker.vue' /* webpackChunkName: "components/rk-address-picker" */).then(c => wrapFunctional(c.default || c))
export const RkBanner = () => import('../../rockitUI/components/components/molecules/RkBanner/RkBanner.vue' /* webpackChunkName: "components/rk-banner" */).then(c => wrapFunctional(c.default || c))
export const RkBar = () => import('../../rockitUI/components/components/molecules/RkBar/RkBar.vue' /* webpackChunkName: "components/rk-bar" */).then(c => wrapFunctional(c.default || c))
export const RkBottomModal = () => import('../../rockitUI/components/components/molecules/RkBottomModal/RkBottomModal.vue' /* webpackChunkName: "components/rk-bottom-modal" */).then(c => wrapFunctional(c.default || c))
export const RkCharacteristic = () => import('../../rockitUI/components/components/molecules/RkCharacteristic/RkCharacteristic.vue' /* webpackChunkName: "components/rk-characteristic" */).then(c => wrapFunctional(c.default || c))
export const RkCallToAction = () => import('../../rockitUI/components/components/molecules/RkCallToAction/RkCallToAction.vue' /* webpackChunkName: "components/rk-call-to-action" */).then(c => wrapFunctional(c.default || c))
export const RkColorPicker = () => import('../../rockitUI/components/components/molecules/RkColorPicker/RkColorPicker.vue' /* webpackChunkName: "components/rk-color-picker" */).then(c => wrapFunctional(c.default || c))
export const RkCheckbox = () => import('../../rockitUI/components/components/molecules/RkCheckbox/RkCheckbox.vue' /* webpackChunkName: "components/rk-checkbox" */).then(c => wrapFunctional(c.default || c))
export const RkFeaturePanel = () => import('../../rockitUI/components/components/molecules/RkFeaturePanel/RkFeaturePanel.vue' /* webpackChunkName: "components/rk-feature-panel" */).then(c => wrapFunctional(c.default || c))
export const RkGallery = () => import('../../rockitUI/components/components/molecules/RkGallery/RkGallery.vue' /* webpackChunkName: "components/rk-gallery" */).then(c => wrapFunctional(c.default || c))
export const RkGallerySlider = () => import('../../rockitUI/components/components/molecules/RkGallerySlider/RkGallerySlider.vue' /* webpackChunkName: "components/rk-gallery-slider" */).then(c => wrapFunctional(c.default || c))
export const RkMenuItem = () => import('../../rockitUI/components/components/molecules/RkMenuItem/RkMenuItem.vue' /* webpackChunkName: "components/rk-menu-item" */).then(c => wrapFunctional(c.default || c))
export const RkModal = () => import('../../rockitUI/components/components/molecules/RkModal/RkModal.vue' /* webpackChunkName: "components/rk-modal" */).then(c => wrapFunctional(c.default || c))
export const RkNotification = () => import('../../rockitUI/components/components/molecules/RkNotification/RkNotification.vue' /* webpackChunkName: "components/rk-notification" */).then(c => wrapFunctional(c.default || c))
export const RkPagination = () => import('../../rockitUI/components/components/molecules/RkPagination/RkPagination.vue' /* webpackChunkName: "components/rk-pagination" */).then(c => wrapFunctional(c.default || c))
export const RkRadio = () => import('../../rockitUI/components/components/molecules/RkRadio/RkRadio.vue' /* webpackChunkName: "components/rk-radio" */).then(c => wrapFunctional(c.default || c))
export const RkReview = () => import('../../rockitUI/components/components/molecules/RkReview/RkReview.vue' /* webpackChunkName: "components/rk-review" */).then(c => wrapFunctional(c.default || c))
export const RkScrollable = () => import('../../rockitUI/components/components/molecules/RkScrollable/RkScrollable.vue' /* webpackChunkName: "components/rk-scrollable" */).then(c => wrapFunctional(c.default || c))
export const RkSearchBar = () => import('../../rockitUI/components/components/molecules/RkSearchBar/RkSearchBar.vue' /* webpackChunkName: "components/rk-search-bar" */).then(c => wrapFunctional(c.default || c))
export const RkSection = () => import('../../rockitUI/components/components/molecules/RkSection/RkSection.vue' /* webpackChunkName: "components/rk-section" */).then(c => wrapFunctional(c.default || c))
export const RkSelect = () => import('../../rockitUI/components/components/molecules/RkSelect/RkSelect.vue' /* webpackChunkName: "components/rk-select" */).then(c => wrapFunctional(c.default || c))
export const RkSteps = () => import('../../rockitUI/components/components/molecules/RkSteps/RkSteps.vue' /* webpackChunkName: "components/rk-steps" */).then(c => wrapFunctional(c.default || c))
export const RkToolbar = () => import('../../rockitUI/components/components/molecules/RkToolbar/RkToolbar.vue' /* webpackChunkName: "components/rk-toolbar" */).then(c => wrapFunctional(c.default || c))
export const RkTooltip = () => import('../../rockitUI/components/components/molecules/RkTooltip/RkTooltip.vue' /* webpackChunkName: "components/rk-tooltip" */).then(c => wrapFunctional(c.default || c))
export const RkYotpoReview = () => import('../../rockitUI/components/components/molecules/RkYotpoReview/RkYotpoReview.vue' /* webpackChunkName: "components/rk-yotpo-review" */).then(c => wrapFunctional(c.default || c))
export const RkYotpoScript = () => import('../../rockitUI/components/components/molecules/RkYotpoScript/RkYotpoScript.vue' /* webpackChunkName: "components/rk-yotpo-script" */).then(c => wrapFunctional(c.default || c))
export const RkAddress = () => import('../../rockitUI/components/components/molecules/RkAddressPicker/_internal/RkAddress.vue' /* webpackChunkName: "components/rk-address" */).then(c => wrapFunctional(c.default || c))
export const RkSelectOption = () => import('../../rockitUI/components/components/molecules/RkSelect/_internal/RkSelectOption.vue' /* webpackChunkName: "components/rk-select-option" */).then(c => wrapFunctional(c.default || c))
export const RkStep = () => import('../../rockitUI/components/components/molecules/RkSteps/_internal/RkStep.vue' /* webpackChunkName: "components/rk-step" */).then(c => wrapFunctional(c.default || c))
export const RkBack = () => import('../../rockitUI/components/components/atoms/RkBack/RkBack.vue' /* webpackChunkName: "components/rk-back" */).then(c => wrapFunctional(c.default || c))
export const RkArrow = () => import('../../rockitUI/components/components/atoms/RkArrow/RkArrow.vue' /* webpackChunkName: "components/rk-arrow" */).then(c => wrapFunctional(c.default || c))
export const RkBadge = () => import('../../rockitUI/components/components/atoms/RkBadge/RkBadge.vue' /* webpackChunkName: "components/rk-badge" */).then(c => wrapFunctional(c.default || c))
export const RkBreadcrumbs = () => import('../../rockitUI/components/components/atoms/RkBreadcrumbs/RkBreadcrumbs.vue' /* webpackChunkName: "components/rk-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const RkButton = () => import('../../rockitUI/components/components/atoms/RkButton/RkButton.vue' /* webpackChunkName: "components/rk-button" */).then(c => wrapFunctional(c.default || c))
export const RkChevron = () => import('../../rockitUI/components/components/atoms/RkChevron/RkChevron.vue' /* webpackChunkName: "components/rk-chevron" */).then(c => wrapFunctional(c.default || c))
export const RkCloseButton = () => import('../../rockitUI/components/components/atoms/RkCloseButton/RkCloseButton.vue' /* webpackChunkName: "components/rk-close-button" */).then(c => wrapFunctional(c.default || c))
export const RkCircleIcon = () => import('../../rockitUI/components/components/atoms/RkCircleIcon/RkCircleIcon.vue' /* webpackChunkName: "components/rk-circle-icon" */).then(c => wrapFunctional(c.default || c))
export const RkColor = () => import('../../rockitUI/components/components/atoms/RkColor/RkColor.vue' /* webpackChunkName: "components/rk-color" */).then(c => wrapFunctional(c.default || c))
export const RkDivider = () => import('../../rockitUI/components/components/atoms/RkDivider/RkDivider.vue' /* webpackChunkName: "components/rk-divider" */).then(c => wrapFunctional(c.default || c))
export const RkHeading = () => import('../../rockitUI/components/components/atoms/RkHeading/RkHeading.vue' /* webpackChunkName: "components/rk-heading" */).then(c => wrapFunctional(c.default || c))
export const RkIcon = () => import('../../rockitUI/components/components/atoms/RkIcon/RkIcon.vue' /* webpackChunkName: "components/rk-icon" */).then(c => wrapFunctional(c.default || c))
export const RkImage = () => import('../../rockitUI/components/components/atoms/RkImage/RkImage.vue' /* webpackChunkName: "components/rk-image" */).then(c => wrapFunctional(c.default || c))
export const RkImagePlaceholder = () => import('../../rockitUI/components/components/atoms/RkImagePlaceholder/RkImagePlaceholder.vue' /* webpackChunkName: "components/rk-image-placeholder" */).then(c => wrapFunctional(c.default || c))
export const RkInput = () => import('../../rockitUI/components/components/atoms/RkInput/RkInput.vue' /* webpackChunkName: "components/rk-input" */).then(c => wrapFunctional(c.default || c))
export const RkLabel = () => import('../../rockitUI/components/components/atoms/RkLabel/RkLabel.vue' /* webpackChunkName: "components/rk-label" */).then(c => wrapFunctional(c.default || c))
export const RkLink = () => import('../../rockitUI/components/components/atoms/RkLink/RkLink.vue' /* webpackChunkName: "components/rk-link" */).then(c => wrapFunctional(c.default || c))
export const RkLoader = () => import('../../rockitUI/components/components/atoms/RkLoader/RkLoader.vue' /* webpackChunkName: "components/rk-loader" */).then(c => wrapFunctional(c.default || c))
export const RkOverlay = () => import('../../rockitUI/components/components/atoms/RkOverlay/RkOverlay.vue' /* webpackChunkName: "components/rk-overlay" */).then(c => wrapFunctional(c.default || c))
export const RkPicture = () => import('../../rockitUI/components/components/atoms/RkPicture/RkPicture.vue' /* webpackChunkName: "components/rk-picture" */).then(c => wrapFunctional(c.default || c))
export const RkPlaceholder = () => import('../../rockitUI/components/components/atoms/RkPlaceholder/RkPlaceholder.vue' /* webpackChunkName: "components/rk-placeholder" */).then(c => wrapFunctional(c.default || c))
export const RkPrice = () => import('../../rockitUI/components/components/atoms/RkPrice/RkPrice.vue' /* webpackChunkName: "components/rk-price" */).then(c => wrapFunctional(c.default || c))
export const RkProperty = () => import('../../rockitUI/components/components/atoms/RkProperty/RkProperty.vue' /* webpackChunkName: "components/rk-property" */).then(c => wrapFunctional(c.default || c))
export const RkQuantitySelector = () => import('../../rockitUI/components/components/atoms/RkQuantitySelector/RkQuantitySelector.vue' /* webpackChunkName: "components/rk-quantity-selector" */).then(c => wrapFunctional(c.default || c))
export const RkRating = () => import('../../rockitUI/components/components/atoms/RkRating/RkRating.vue' /* webpackChunkName: "components/rk-rating" */).then(c => wrapFunctional(c.default || c))
export const RkSiteWrapper = () => import('../../rockitUI/components/components/atoms/RkSiteWrapper/RkSiteWrapper.vue' /* webpackChunkName: "components/rk-site-wrapper" */).then(c => wrapFunctional(c.default || c))
export const RkSize = () => import('../../rockitUI/components/components/atoms/RkSize/RkSize.vue' /* webpackChunkName: "components/rk-size" */).then(c => wrapFunctional(c.default || c))
export const RkSkeleton = () => import('../../rockitUI/components/components/atoms/RkSkeleton/RkSkeleton.vue' /* webpackChunkName: "components/rk-skeleton" */).then(c => wrapFunctional(c.default || c))
export const RkTextarea = () => import('../../rockitUI/components/components/atoms/RkTexarea/RkTextarea.vue' /* webpackChunkName: "components/rk-textarea" */).then(c => wrapFunctional(c.default || c))
export const RkTransitionHeight = () => import('../../rockitUI/components/components/atoms/RkTransitionHeight/RkTransitionHeight.vue' /* webpackChunkName: "components/rk-transition-height" */).then(c => wrapFunctional(c.default || c))
export const Banner = () => import('../../components/storyblok/Banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c))
export const BannersGrid = () => import('../../components/storyblok/BannersGrid.vue' /* webpackChunkName: "components/banners-grid" */).then(c => wrapFunctional(c.default || c))
export const ButtonComponent = () => import('../../components/storyblok/ButtonComponent.vue' /* webpackChunkName: "components/button-component" */).then(c => wrapFunctional(c.default || c))
export const Checkout = () => import('../../components/storyblok/Checkout.vue' /* webpackChunkName: "components/checkout" */).then(c => wrapFunctional(c.default || c))
export const CloudinaryVideoBanner = () => import('../../components/storyblok/CloudinaryVideoBanner.vue' /* webpackChunkName: "components/cloudinary-video-banner" */).then(c => wrapFunctional(c.default || c))
export const Feature = () => import('../../components/storyblok/Feature.vue' /* webpackChunkName: "components/feature" */).then(c => wrapFunctional(c.default || c))
export const FeaturePanel = () => import('../../components/storyblok/FeaturePanel.vue' /* webpackChunkName: "components/feature-panel" */).then(c => wrapFunctional(c.default || c))
export const Grid = () => import('../../components/storyblok/Grid.vue' /* webpackChunkName: "components/grid" */).then(c => wrapFunctional(c.default || c))
export const Headline = () => import('../../components/storyblok/Headline.vue' /* webpackChunkName: "components/headline" */).then(c => wrapFunctional(c.default || c))
export const Page = () => import('../../components/storyblok/Page.vue' /* webpackChunkName: "components/page" */).then(c => wrapFunctional(c.default || c))
export const Partners = () => import('../../components/storyblok/Partners.vue' /* webpackChunkName: "components/partners" */).then(c => wrapFunctional(c.default || c))
export const ProductTileBanner = () => import('../../components/storyblok/ProductTileBanner.vue' /* webpackChunkName: "components/product-tile-banner" */).then(c => wrapFunctional(c.default || c))
export const Products = () => import('../../components/storyblok/Products.vue' /* webpackChunkName: "components/products" */).then(c => wrapFunctional(c.default || c))
export const PromoBar = () => import('../../components/storyblok/PromoBar.vue' /* webpackChunkName: "components/promo-bar" */).then(c => wrapFunctional(c.default || c))
export const RichText = () => import('../../components/storyblok/RichText.vue' /* webpackChunkName: "components/rich-text" */).then(c => wrapFunctional(c.default || c))
export const SbFooter = () => import('../../components/storyblok/SbFooter.vue' /* webpackChunkName: "components/sb-footer" */).then(c => wrapFunctional(c.default || c))
export const SbNewsletter = () => import('../../components/storyblok/SbNewsletter.vue' /* webpackChunkName: "components/sb-newsletter" */).then(c => wrapFunctional(c.default || c))
export const TableComponent = () => import('../../components/storyblok/TableComponent.vue' /* webpackChunkName: "components/table-component" */).then(c => wrapFunctional(c.default || c))
export const Usp = () => import('../../components/storyblok/Usp.vue' /* webpackChunkName: "components/usp" */).then(c => wrapFunctional(c.default || c))
export const AlgoliaTrendingItems = () => import('../../components/storyblok/algoliaTrendingItems.vue' /* webpackChunkName: "components/algolia-trending-items" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
